<template>
  <div class="card" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Costing Name</th>
            <th>Costing Number</th>
            <th>Product</th>
            <th>Customer</th>
            <th>Costing Quantity</th>
            <th class="text-right">Bill of material cost</th>
            <th class="text-right">Total sale price</th>
            <th>Currency</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in productCosting" :key="i">
            <td> {{ item.date }}</td>
            <td> {{ item.product_costing_name }}</td>
            <td> {{ item.bill_number }}</td>
            <td> {{ item.product_name }}</td>
            <td> {{ item.customer_name }}</td>
            <td> {{ item.costing_qty }}</td>
            <td class="text-right"> {{ commaFormat(item.bom_cost) }}</td>
            <td class="text-right"> {{ commaFormat(item.total_sales_price) }}</td>
            <td> {{ item.currency }}</td>
            <td>
              <button class="btn btn-primary btn-sm"
                      @click="goToEdit(item.id)"
                      style="cursor: pointer"
              >
                <i class="fas fa-eye"></i>
              </button>
              <button class="btn btn-secondary btn-sm"
                      @click="goToPrint(item.id)"
                      style="cursor: pointer; margin-left: 10px"
              >
                <i class="fas fa-print"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-if="!productCosting.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import figureFormatter from '@/services/utils/figureFormatter';
import {useRoute, useRouter} from "vue-router";

const {commaFormat} = figureFormatter()
const props = defineProps({
  productCosting: Array
})

const route = useRoute()
const router = useRouter()
const goToEdit = (id) => {
  //TODO::need next PR
}
const goToPrint = (id) => {
  //TODO::need next PR
}
</script>
